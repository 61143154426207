import React from 'react'
import LightboxLayout from '../../components/lightboxLayout'

const Page = () => {
  return (
    <LightboxLayout>
      <h2>ROOT PLANING/CURETTAGE</h2>
      <h3>What is gum disease?</h3>
      <p>Gum disease affects the gums and bone supporting your teeth.  It begins with plaque,  a soft, sticky substance  that builds up on your teeth. Plaque is mostly made up of bacteria, which feed on sugar from food and drink. Tartar, formed by hardened plaque, helps plaque to gather and makes it harder to remove.</p>
      <p>If plaque is allowed to build up, the bacteria in it can make your gums sore and infected; they will look red and puffy, and they will probably bleed when you brush your teeth.</p>
      <p>The gum will then start to become detached from the tooth, forming "pockets" in which plaque can gather- and bone supporting  the tooth will slowly be lost. Because this process  is usually  painless, it can become  very bad without you noticing. If left unchecked, gum disease will lead to the loss of teeth.</p>
      <h3>What is root planing /curettage?</h3>
      <p>Root planing is a way of helping  to halt gum disease.  It involves "deep" scaling, to clean parts of teeth below the gumline, which cannot be reached  with a toothbrush. Root planing cleans out the pockets, and removes plaque and tartar from the tooth roots.</p>
      <h3>What does the dentist or dental hygienist do?</h3>
      <p>Dentists and hygienists  use two types of instrument for root planing:</p>
      <ol>
        <li>Hand scalers come in different sizes and shapes  to reach different  parts of the teeth. This is why you will see the dentist or hygienist  changing  instruments quite often.</li>
        <li>Electric scalers use a tip that vibrates very fast in a stream of water. The water is removed from your mouth using a small suction device. A hand scaler is also used along with an electric scaler, to check whether the roots are completely clean.</li>
      </ol>
      <p>After a tooth has been root planed,  the pocket should shrink, making the gum sit closer to the tooth. You then need to be especially careful about cleaning the teeth above the gumline. Root planing will probably need to be repeated  regularly.</p>
      <p>Root planing takes longer than a normal scale and polish, and is often done under a local anaesthetic. Your mouth might be treated in sections, at more than one visit.</p>
      <p>Points to remember:</p>
      <ul>
        <li>Root planing can help stop gum disease becoming worse and prevent tooth loss.</li>
        <li>Like scaling  and polishing, root planing helps you to keep your teeth and gums clean at home.  Dentists and dental hygienists cannot keep your mouth  healthy by themselves. Your own cleaning is just as important.</li>
        <li>To reduce  the risk of your gum disease progressing, do not smoke,  and eat a healthy,  balanced diet.</li>
      </ul>
    </LightboxLayout>
  )
}

export default Page